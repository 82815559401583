<!--
 * @Author: gjm
 * @Date: 2022-08-08 13:18:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-08 11:40:17
 * @Description: 在线咨询-回应
-->
<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: 12px !important;
}
</style>

<template>
  <a-modal
    v-model="questionContinue"
    class="create-question"
    :width="580"
    title="创建问题"
    @cancel="handleCancel"
    okText="提交"
  >
    <div class="question-box">
      <a-form :form="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
        <a-form-item label="问题"><span>xx文件中有个不知道怎么翻译</span></a-form-item>
        <a-form-item label="解决办法"><span>xx文件中有个不知道怎么翻译</span></a-form-item>

        <a-form-item label="追问">
          <a-textarea
            v-model="value"
            placeholder="Controlled autosize"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            v-decorator="['note', { rules: [{ required: true, message: 'Please input your note!' }] }]"
          />
        </a-form-item>

        <a-form-item label="语言对">
          <a-select :disabled="true" default-value="lucy" style="width: 215px" @change="handleChange">
            <a-select-option value="jack"> Jack </a-select-option>
            <a-select-option value="lucy"> lucy </a-select-option>
          </a-select>
          <span style="margin-left: 10px; margin-right: 10px; font-size: 18px"> > </span>
          <a-select :disabled="true" default-value="lucy" style="width: 215px" @change="handleChange">
            <a-select-option value="jack"> Jack </a-select-option>
            <a-select-option value="lucy"> lucy </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="附件">
          <a-upload
            class="BatchImportPriceBtn"
            accept=".xlsx"
            :beforeUpload="handleBeforeUpload"
            :action="uploadAction"
            :showUploadList="false"
            :multiple="false"
            :disabled="uploading"
            :withCredentials="true"
            :headers="uploadHeaders"
            :data="uploadParam"
            @change="handleUploadChange"
          >
            <a-button type="primary" :loading="uploading"> <a-icon type="upload" v-if="!uploading" />上传附件</a-button>
            <div style="margin-top: 10px">支持doc、docx、xlsx、xls、pdf，且大小不超过10M</div>
          </a-upload>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'QuestionRespond',
  props: {
    questionContinue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploading: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCancel() {
      this.$emit('closeQuestionContinue', false)
    },
  },
}
</script>
